<template>
  <div>
    <div class="row mb-5">
      <div
        v-if="warehouse === null || warehouse === undefined"
        class="col-12 text-center my-3"
      >
        <div class="failed badge w-100">
          <span class="bold-19">{{
            $t("productHistory.noWarehouseSelected2")
          }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectedItems="selectedItems"
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="sales"
              v-model:selectedLineOption="selectedLineOption"
              v-on:addNewRow="addNewRow"
              :show-languages="false"
              :show-currencies="false"
              :show-delete="false"
              path="orders"
              :methods="methods"
              :export-url="getUrl(true)"
              :btns="[
                {
                  title: this.$t('order.refundList'),
                  method: () => {
                    this.setRefundFilter();
                  },
                },
                { title: this.$t('order.collectList'), url: `/sales/collects` },
                { title: this.$t('order.packingList'), url: '/sales/products' },
                { title: this.$t('order.returnList'), url: '/sales/return' },
              ]"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="sales"
              v-model:select-all="selectAll"
              type="sales"
              v-on:selected="(e) => (selectedItems = e)"
              v-on:update:val="valUpdated"
              :key="tableKey"
              :show-pin="false"
              :show-order="false"
              v-on:loadMore="loadMore"
              :fix-height="true"
              :load-in-process="loadInProcess"
            />
          </div>
        </div>
      </div>
    </div>
    <new-sale ref="newSaleModal" v-on:newItem="newItem" />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`outgoing.noWarehouseSelected`)"
      :text="$t('order.pleaseSelectAWarehouse')"
      :show-ok="false"
      cancel-button="Ok"
    />
    <ConfirmModal
      ref="multipleEditModal"
      :title="`Edit ${selectedItems.length} orders`"
      :text="`<p>You will edit<b> ${selectedItems.length}</b> orders</p>
  <p>You will edit all selected rows.</p>`"
      cancel-button="Modify only edited row"
      :ok-button="`Edit selected ${selectedItems.length} rows`"
      v-on:success="multipleEdit(true)"
      v-on:cancel="multipleEdit(false)"
    />
    <ConfirmModal
      ref="confirmModal"
      :title="confirmText.title"
      :text="confirmText.text"
      v-on:success="confirmText.success()"
    />
    <OrderMultiplePayment
      v-model="paymentElements"
      v-model:is-refund="isRefund"
      ref="paymentsModal"
    />
    <Pos ref="posModal" v-on:newItem="newItem" />
  </div>
</template>

<script>
import TopBar from "../../components/lists/TopBar";
import DynamicTable from "../../components/lists/DynamicTable";
import NewSale from "@/components/modals/NewSale";
import http from "../../modules/http";
import PaymentStatuses from "@/modules/PaymentStatuses";
import OrderStatuses from "@/modules/OrderStatuses";
import lineOptions from "@/modules/lineOptions";
import DeliveredStatuses from "@/modules/DeliveredStatuses";
import ConfirmModal from "@/components/modals/ConfirmModal";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import OrderMultiplePayment from "@/components/modals/OrderMultiplePayment";
import Pos from "@/components/modals/Pos";

export default {
  name: "Sales",
  data() {
    return {
      store: useStore(),
      tableKey: null,
      rows: [],
      lastMultipleAction: undefined,
      lastMultipleId: undefined,
      selectedLineOption: 1,
      lineOptions: [],
      selectAll: false,
      sales: [],
      isRefund: false,
      paymentElements: [],
      selectedItems: [],
      methods: [],
      deliveryMethods: [],
      paymentMethods: [],
      filterOptions: [],
      noReloadFilters: false,
      loadId: undefined,
      filtersTimeOut: null,
      firstLoad: true,
      canLoadMore: true,
      page: 1,
      loadInProcess: false,
      confirmText: {
        title: "",
        text: "",
        success() {},
      },
    };
  },
  computed: {
    user() {
      return this.$route.params.user;
    },
    storageUrl() {
      return process.env.VUE_APP_FILES;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    webshops() {
      return this.store.state.webshops;
    },
    date() {
      return this.store.state.topBar.date;
    },
    filters() {
      return this.store.state.filters?.orders;
    },
    countries() {
      return this.store.state.countries;
    },
    q() {
      return this.store.state.topBar.q;
    },
  },
  watch: {
    user() {
      this.page = 1;
      this.loadData();
    },
    warehouse: {
      deep: true,
      handler() {
        this.page = 1;
        this.loadData();
      },
    },
    date: {
      deep: true,
      handler() {
        this.page = 1;
        this.loadData();
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
    filters: {
      deep: true,
      handler() {
        if (this.noReloadFilters) {
          return;
        }
        if (this.filterPage !== "orders" || this.firstLoad) {
          setTimeout(() => {
            this.firstLoad = false;
          }, 100);
          return;
        }
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.page = 1;
          this.loadData();
        }, 450);
      },
    },
  },
  mounted() {
    window.apps.subscribe(
      "generate:invoice",
      (id) => {
        if (this.selectedItems.length > 0) {
          this.lastMultipleId = id;
          this.lastMultipleAction = "generate:invoice";
          this.$refs.multipleEditModal.showModal();
        } else {
          this.generateInvoice(id);
        }
      },
      "default"
    );
    window.apps.subscribe(
      "generate:delivery",
      (id) => {
        if (this.selectedItems.length > 0) {
          this.lastMultipleId = id;
          this.lastMultipleAction = "generate:delivery";
          this.$refs.multipleEditModal.showModal();
        } else {
          this.generateDelivery(id);
        }
      },
      "default"
    );

    window.apps.subscribe(
      "modal:receiveMoney",
      (id) => {
        this.isRefund = false;

        if (this.selectedItems.length > 0) {
          this.paymentElements = this.selectedItems;
          this.$refs.paymentsModal.showModal();
        } else {
          this.paymentElements = [this.sales.find((e) => e.id === id)];
          this.$refs.paymentsModal.showModal();
        }
      },
      "default"
    );
    window.apps.subscribe(
      "modal:sendMoney",
      (id) => {
        this.isRefund = true;

        if (this.selectedItems.length > 0) {
          this.paymentElements = this.selectedItems;
          this.$refs.paymentsModal.showModal();
        } else {
          this.paymentElements = [this.sales.find((e) => e.id === id)];
          this.$refs.paymentsModal.showModal();
        }
      },
      "default"
    );

    window.apps.subscribe(
      "order:deny",
      (id) => {
        this.isRefund = true;

        this.confirmText.title = `<span class="text-danger bold-24">${this.$t(
          "order.denyOrder"
        )}</span>`;
        this.confirmText.text = this.$t("order.denyOrderConfirm");

        this.confirmText.success = function () {
          let deny = {
            order_id: id,
            deny_type: 1,
          };
          http.fetch("/orders/deny", deny, true);
        };

        this.$refs.confirmModal.showModal();
      },
      "default"
    );

    window.apps.subscribe(
      "order:accept",
      (id) => {
        this.isRefund = true;

        this.confirmText.title = this.$t("order.acceptOrders", {
          order: this.selectedItems.length > 1 ? "orders" : "order",
        });
        this.confirmText.text = this.$t("order.acceptOrdersText", {
          number:
            this.selectedItems.length > 0
              ? this.selectedItems.length + " selected"
              : "this",
          order: this.selectedItems.length > 1 ? "orders" : "order",
        });

        const _this = this;
        this.confirmText.success = function () {
          if (_this.selectedItems.length > 0) {
            let accept = {
              orders: _this.selectedItems.map((o) => o.id),
            };
            http.fetch("/orders/accept", accept, true);
          } else {
            let accept = {
              order_id: id,
            };
            http.fetch("/orders/accept", accept, true);
          }
        };

        this.$refs.confirmModal.showModal();
      },
      "default"
    );

    this.loadData();

    ws.init();

    ws.subscribe("modify", "order", (e) => {
      let i = this.sales.findIndex((o) => o.id === e.objectId);
      if (i === undefined) {
        return;
      }
      let obj = this.sales[i];
      if (obj !== undefined) {
        obj[e.rowKey] = e.value;
      }
    });
  },
  methods: {
    getUrl(exportUrl = false) {
      let url =
        `/orders${exportUrl ? "/export/{type}" : ""}?page=` +
        (exportUrl ? 1 : this.page) +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "");

      if (this.user !== null && this.user !== undefined) {
        url += `&customer=${this.user}`;
      }

      if (this.filterPage !== "orders") {
        url += `&status[]=0&status[]=1&status[]=2`;
      } else {
        if (Object.keys(this.filters).length > 0) {
          for (const filter in this.filters) {
            if (Array.isArray(this.filters[filter].value)) {
              for (const value of this.filters[filter].value) {
                url += `&${filter}[]=${value}`;
              }
            } else {
              url += `&${filter}=${this.filters[filter].value}`;
            }
          }
        }
      }
      return url;
    },
    loadMore() {
      if (this.canLoadMore) {
        this.canLoadMore = false;
        this.loadInProcess = true;
        this.page++;
        this.loadData();
      } else {
        this.loadInProcess = false;
      }
    },
    loadData() {
      const loadId = (this.loadId = Math.random());
      if (this.selectedWarehouse?.shop_id > 0) {
        this.methods = [
          {
            title: this.$t("order.pos"),
            icon: "scanner",
            always: true,
            method: () => {
              if (this.warehouse === null) {
                this.$refs.noWarehouseModal.showModal();
              } else {
                this.$refs.posModal.showModal();
              }
            },
          },
        ];
      } else {
        this.methods = [];
      }

      if (this.page === 1) {
        this.sales = undefined;
      }
      http.fetch(this.getUrl()).then((data) => {
        if (loadId !== this.loadId) {
          return;
        }
        if (this.page === 1) {
          this.deliveryMethods = data.delivery_methods;
          this.paymentMethods = data.payment_methods;
          for (const paymentMethod of this.paymentMethods) {
            if (
              paymentMethod.icon !== null &&
              paymentMethod.icon !== undefined
            ) {
              paymentMethod.icon = this.storageUrl + "/" + paymentMethod.icon;
            }
          }
          let val = this.filterOptions.findIndex(
            (e) => e.key === "payment_methods"
          );
          if (val > -1) {
            this.noReloadFilters = true;
            this.filterOptions[val].values = data.payment_methods;
            setTimeout(() => {
              this.noReloadFilters = false;
            }, 50);
          }

          if (this.lineOptions.length === 0) {
            this.rows = lineOptions.init(data.rows, data.line_options);
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0].id;

            let status = this.rows.find((e) => e.key === "status");
            if (status !== undefined) {
              status.values = OrderStatuses;
              status.center = true;
            }

            status = this.rows.find((e) => e.key === "payment_status");
            if (status !== undefined) {
              status.values = PaymentStatuses;
              status.center = true;
            }

            if (status !== undefined) {
              status = this.rows.find((e) => e.key === "delivered_status");
              status.values = DeliveredStatuses;
              status.center = true;
            }

            this.rows.find((e) => e.key === "last_invoice").center = true;

            if (this.filterPage !== "orders") {
              this.setFilters();
            }
          }
          this.sales = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.sales.push(row);
          }
        }
        this.canLoadMore = data.data.length > 0;
        this.loadInProcess = false;
      });
    },
    setFilters(returnList = false) {
      this.firstLoad = true;
      setTimeout(() => {
        this.firstLoad = false;
      }, 2000);
      if (returnList) {
        this.filterOptions = [];
      }
      let filterOptions = this.filterOptions;
      filterOptions.push({
        name: this.$t("order.status"),
        type: "string",
        key: "status",
        value: returnList ? {} : { 0: true, 1: true, 2: true },
        values: Object.values(OrderStatuses),
        valueName: "name",
      });
      filterOptions.push({
        name: this.$t("order.paymentStatus"),
        type: "string",
        key: "payment_status",
        value: returnList ? { 6: true, 8: true } : {},
        values: Object.values(PaymentStatuses),
        valueName: "name",
      });

      filterOptions.push({
        name: this.$t("order.waitForReturn"),
        type: "string",
        key: "wait_for_return",
        value: returnList ? { 0: true } : {},
        values: [
          { id: 1, name: "Yes" },
          { id: 0, name: "No" },
        ],
        valueName: "name",
      });
      filterOptions.push({
        name: this.$t("order.paymentMethods"),
        type: "string",
        key: "payment_methods",
        value: {},
        values: this.paymentMethods,
        valueName: "name",
      });
      filterOptions.push({
        name: this.$t("order.deliveryStatus"),
        type: "string",
        key: "delivery_status",
        value: {},
        values: Object.values(DeliveredStatuses),
        valueName: "name",
      });
      filterOptions.push({
        name: this.$t("order.deliveryMethods"),
        type: "string",
        key: "delivery_methods",
        value: {},
        values: this.deliveryMethods,
        valueName: "name",
      });
      filterOptions.push({
        name: this.$t("order.afterStatus"),
        type: "string",
        key: "after_status",
        value: {},
        values: [
          { id: "return", name: this.$t("orderAfterStatus.return") },
          { id: "change", name: this.$t("orderAfterStatus.change") },
          { id: "refused", name: this.$t("orderAfterStatus.refused") },
          { id: "deny", name: this.$t("orderAfterStatus.denied") },
        ],
        valueName: "name",
      });

      filterOptions.push({
        name: this.$t("order.saleChannel"),
        type: "string",
        key: "webshop",
        value: {},
        values: this.webshops,
        valueName: "name",
      });

      filterOptions.push({
        name: this.$t("order.country"),
        type: "string",
        key: "country",
        value: {},
        values: this.countries,
        valueName: "name",
      });

      this.$store.commit("setFilterPage", "orders");
      if (!returnList) {
        this.filters["status"] = { value: [0, 1, 2] };
      } else {
        this.filters["payment_status"] = { value: [6, 8] };
        this.filters["wait_for_return"] = { value: [0] };
      }
      this.$store.commit("setFilterOptions", this.filterOptions);
      this.$store.commit("setFilterOptionsStock", false);
      this.$store.commit("setFilterOptionsPrice", true);
    },
    setRefundFilter() {
      this.page = 1;
      this.setFilters(true);
      this.loadData();
    },
    addNewRow() {
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.$refs.newSaleModal.showModal();
      }
    },
    newItem(data) {
      this.sales.unshift(data);
      this.tableKey++;
    },
    valUpdated(val, row, id) {
      let data = {};

      if (row === "invoice_address") {
        data["invoice_city"] = val.city;
        data["invoice_street"] = val.street;
        data["invoice_house"] = val.house;
        data["invoice_post_code"] = val.post_code;
        data["country_id"] = val.country_id;
      } else if (row === "delivery_address") {
        data["delivery"] = val;
        delete data["delivery"].country;
      } else if (row === "tags") {
        data[row] = val.flatMap((e) => e.id);
      } else {
        data[row] = val;
      }
      http.fetch("/orders/" + id, data, true, "PUT").then((data) => {
        console.log(data);
      });
    },
    multipleEdit(all = false) {
      if (this.lastMultipleAction === "generate:invoice") {
        if (all) {
          this.generateInvoiceAll();
        } else {
          this.generateInvoice(this.lastMultipleId);
        }
      } else if (this.lastMultipleAction === "generate:delivery") {
        if (all) {
          this.generateDeliveryAll();
        } else {
          this.generateDelivery(this.lastMultipleId);
        }
      }

      this.lastMultipleAction = undefined;
      this.lastMultipleId = undefined;
    },
    generateInvoiceAll() {
      let ids = this.selectedItems
        .filter((e) => e.generate_invoice !== false)
        .map((e) => e.id);

      this.generateInvoice(ids);
    },
    generateInvoice(id) {
      http.fetch("/orders/invoice", { order_id: id }, true);
    },
    generateDeliveryAll() {
      let ids = this.selectedItems
        .filter((e) => e.last_public_tracking_code === null)
        .map((e) => e.id);

      this.generateDelivery(ids);
    },
    generateDelivery(id) {
      http.fetch("/orders/delivery", { order_id: id }, true);
    },
  },
  components: {
    Pos,
    OrderMultiplePayment,
    ConfirmModal,
    NewSale,
    DynamicTable,
    TopBar,
  },
};
</script>
