<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <button
                type="button"
                class="btn btn-toolbar"
                :aria-label="$t(`pos.back`)"
                v-on:click="back()"
                v-if="step > 1"
              >
                <BaseIcon name="left-arrow2" />
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                :aria-label="$t(`orderChange.close`)"
              ></button>
            </div>
          </div>
          <div class="row" v-if="step === 1 || step === 3">
            <div class="col-12 text-center">
              <Vue3Lottie
                ref="icon"
                :animationData="iconJSON"
                :height="160"
                :width="160"
                :loop="loop"
                :key="iconKey"
              />
            </div>
            <div class="col-12 text-center mb-4 pb-2 border-bottom">
              <h1 class="bold-19">{{ packagingInfo.status }}</h1>
              <p v-if="packagingInfo.text" class="regular-14 mb-4">
                {{ packagingInfo.text }}
              </p>
              <p v-else class="regular-14 mb-4">
                <button class="btn btn-link" v-on:click="step = 99">
                  {{ $t("pos.manual") }}
                </button>
              </p>
              <button
                v-if="retryPrintBtn"
                class="btn btn-primary py-4 px-5"
                v-on:click="printReceiptAgain()"
                :class="{ 'loading-spin': loadAddBtn }"
                :disabled="loadAddBtn"
              >
                <span>{{ $t("pos.tryAgain") }}</span>
              </button>
            </div>
            <div
              class="col-3 text-center"
              v-for="(p, i) in addProducts"
              :key="p.id"
              v-show="p.id && step === 1"
            >
              <img
                :src="
                  mediaServer +
                  '/' +
                  p.products.find((e) => e.variant_id === p.id)?.image
                "
                v-if="
                  p.products.find((e) => e.variant_id === p.id)?.image !==
                    null &&
                  p.products.find((e) => e.variant_id === p.id)?.image !==
                    undefined
                "
                class="product-image"
                alt=""
              />
              <BaseIcon v-else name="no-image" class="product-image" />
              <div class="bold-16 mt-2">
                <span class="text-danger bold-24" v-if="p.quantity > 1"
                  >{{ p.quantity }}x</span
                >
                {{ p.products.find((e) => e.variant_id === p.id)?.name }}
              </div>
              <div>
                <span
                  v-for="cs in p.products.find((e) => e.variant_id === p.id)
                    ?.caracteristics"
                  :key="cs"
                  class="d-inline-block m-2"
                >
                  {{ cs }}
                </span>
              </div>
              <div class="bold-14">
                {{ getPrice(p) }}
                {{ selectedWarehouse?.default_currency }}
              </div>
              <div>
                <button class="btn btn-link" v-on:click="deleteProduct(i)">
                  <BaseIcon name="trash" />
                </button>
              </div>
            </div>
          </div>
          <div
            v-else-if="step === 99"
            class="row custom-input mx-md-5 px-md-5 whitebg-input"
            v-for="p in addProducts"
            :key="p.id"
          >
            <div class="col-3">
              <label class="form-label bold-12 text-gray">{{
                $t("global.quantity")
              }}</label>
              <input
                type="number"
                v-model="p.quantity"
                class="form-control"
                min="1"
              />
            </div>
            <div class="col-9">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("global.product")
                }}</label>
                <v-select
                  v-model:options="p.products"
                  v-on:search="(s, k) => fetchProducts(s, k, p)"
                  v-model="p.id"
                  :filterable="false"
                  :placeholder="$t(`orderChange.selectProduct`)"
                  :reduce="(c) => c.variant_id"
                  v-on:option:selected="(e) => changeProducts(e.variant_id)"
                  v-on:option:deselected="() => changeProducts(0)"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching && search.length > 3">
                      <span
                        v-html="$t('global.no-product', { q: search })"
                      ></span>
                    </template>
                    <span v-else>{{ $t("global.select-start-product") }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <div class="row">
                      <div class="col-auto px-0">
                        <img
                          :src="mediaServer + '/' + option?.image"
                          v-if="
                            option?.image !== null &&
                            option?.image !== undefined
                          "
                          class="product-image"
                          alt=""
                        />
                        <BaseIcon
                          v-else
                          name="no-image"
                          class="product-image"
                        />
                      </div>
                      <div class="col">
                        <div>
                          <b>{{ option.name }}</b>
                        </div>
                        <div>
                          <span
                            v-for="char in option.caracteristics"
                            :key="char"
                            class="me-2"
                          >
                            {{ char }}
                          </span>
                          <span class="me-2">
                            {{ option.serial }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:option="option">
                    <span class="me-2">
                      {{ option.serial }}
                    </span>
                    <span
                      v-for="char in option.caracteristics"
                      :key="char"
                      class="me-2"
                    >
                      {{ char }}
                    </span>
                    <b>{{ option.name }}</b>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <Payment
            v-if="step === 2"
            :total="total - totalPayed"
            v-model:selected-payment="selectedPayment"
            v-model:payed="payed"
            :paymentID="paymentID"
            :need="total - totalPayed"
          />
          <div v-if="showInvoiceMethods && step > 1" class="row">
            <h2>{{ $t("pos.InvoiceDetails") }}</h2>
            <div class="col-12 col-md-6 row">
              <div class="col-6 py-2 my-4">
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newCustomer.person")
                  }}</span>
                  <input type="radio" :value="0" v-model="newOrder.type" /><span
                    class="checkmark mx-2"
                  ></span>
                </label>
              </div>
              <div class="col-6 py-2 my-4">
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newCustomer.company2")
                  }}</span>
                  <input type="radio" :value="1" v-model="newOrder.type" /><span
                    class="checkmark mx-2"
                  ></span>
                </label>
              </div>
            </div>
            <div class="col-6 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("finance.email")
              }}</label>
              <v-select
                v-model:options="customers"
                v-on:search="(s, k) => fetchCustomers(s, k)"
                v-model="newOrder.email"
                :filterable="false"
                placeholder=""
                taggable
                :create-option="(email) => ({ email: email })"
                label="email"
                :reduce="(c) => c.email"
                v-on:option:selected="(e) => changeCustomers(e)"
              />
            </div>
            <div class="col-12 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("address.country")
              }}</label>
              <v-select
                :options="countries"
                v-model="newOrder.country_id"
                label="name"
                :placeholder="$t(`address.selectCountry`)"
                :reduce="(c) => c.id"
              />
            </div>
            <div class="col-6 mb-3" v-if="newOrder.type === 0">
              <label class="form-label bold-12 text-gray">
                {{ $t("finance.firstName") }}
              </label>
              <input class="form-control" v-model="newOrder.first_name" />
            </div>
            <div class="col-6 mb-3" v-if="newOrder.type === 0">
              <label class="form-label bold-12 text-gray">
                {{ $t("finance.lastName") }}
              </label>
              <input class="form-control" v-model="newOrder.last_name" />
            </div>
            <div
              class="col-6 mb-3"
              v-if="
                newOrder.type === 1 &&
                selectedCountry?.is_eu &&
                selectedCountry?.id !== selectedWarehouse?.company?.country_id
              "
            >
              <label class="form-label bold-12 text-gray">
                {{ $t("companies.euTaxNumber") }}
              </label>
              <input
                class="form-control"
                v-on:change="
                  searchBusiness(newOrder.eu_tax_code, selectedCountry.code)
                "
                v-model="newOrder.eu_tax_code"
              />
            </div>
            <div class="col-6 mb-3" v-else-if="newOrder.type === 1">
              <label class="form-label bold-12 text-gray">
                {{ $t("companies.localTaxNumber") }}
              </label>
              <input
                class="form-control"
                v-on:change="
                  searchBusiness(newOrder.tax_code, selectedCountry.code)
                "
                v-model="newOrder.tax_code"
              />
            </div>
            <div class="col-6 mb-3" v-if="newOrder.type === 1">
              <label class="form-label bold-12 text-gray">
                {{ $t("newSale.companyName") }}
              </label>
              <input class="form-control" v-model="newOrder.business_name" />
            </div>
            <div
              class="col-5 mb-3"
              :class="selectedCountry?.need_area ? 'col-6' : 'col-12'"
              v-if="selectedCountry?.need_state"
            >
              <label class="form-label bold-12 text-gray">{{
                stateTypes[setNullToZero(selectedCountry?.state_type)]?.name
              }}</label>
              <v-select
                v-model:options="states"
                v-model="newOrder.invoice_state"
                placeholder=""
                taggable
                label="state_name"
                :reduce="(c) => c.state_name"
              />
            </div>
            <div
              class="mb-3"
              :class="selectedCountry?.need_state ? 'col-6' : 'col-12'"
              v-if="selectedCountry?.need_area"
            >
              <label class="form-label bold-12 text-gray">{{
                $t("newSale.area")
              }}</label>
              <input class="form-control" v-model="newOrder.invoice_area" />
            </div>
            <div class="col-5 mb-3" v-if="selectedCountry?.need_post_code">
              <label class="form-label bold-12 text-gray">{{
                $t("companies.postCode")
              }}</label>
              <input
                class="form-control"
                v-model="newOrder.invoice_post_code"
              />
            </div>
            <div
              class="mb-3"
              :class="selectedCountry?.need_post_code ? 'col-7' : 'col-12'"
            >
              <label class="form-label bold-12 text-gray">{{
                $t("address.city")
              }}</label>
              <input class="form-control" v-model="newOrder.invoice_city" />
            </div>
            <div class="col-9 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("address.address")
              }}</label>
              <input class="form-control" v-model="newOrder.invoice_street" />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("characteristicsModal.number")
              }}</label>
              <input class="form-control" v-model="newOrder.invoice_house" />
            </div>
          </div>
          <div class="row align-items-center" style="height: 5em">
            <div class="col-3">
              <input
                type="text"
                :placeholder="$t(`pos.coupon`)"
                v-on:change="checkCoupon"
                :disabled="couponAccepted"
                class="form-control"
                v-model="coupon"
              />
              <div class="col-12 failed badge w-100" v-if="couponError">
                <span>{{ couponError }}</span>
              </div>
            </div>
            <div
              class="col text-end mt-4"
              v-if="
                step === 1 ||
                step === 99 ||
                (selectedPayment?.type === 3 &&
                  payed < remainingNeedToPay &&
                  payed > 0)
              "
            >
              <button
                class="btn ms-2"
                :class="
                  payment.type === 5 || payment.type === 3
                    ? 'btn-type-2'
                    : 'btn-type-4'
                "
                v-show="
                  (payment.type !== 4 || shop?.iban !== null) &&
                  (payment.type !== 3 || step === 1 || step === 99)
                "
                v-on:click="next(payment)"
                v-for="payment in shop?.paymentMethods"
                :key="payment.id"
              >
                <span>{{ payment.name }}</span>
              </button>
            </div>
            <div
              class="col text-end mt-4"
              v-else-if="
                step === 2 &&
                (selectedPayment?.type !== 3 || payed >= remainingNeedToPay)
              "
            >
              <button
                class="btn ms-2"
                v-on:click="finish()"
                :class="{
                  'loading-spin': loadAddBtn && loadAddBtnId === 0,
                  'btn-type-4': showInvoiceMethods,
                  'btn-type-2': !showInvoiceMethods,
                }"
                :disabled="loadAddBtn"
              >
                <span>{{ $t("pos.receipt") }}</span>
              </button>
              <template v-if="showInvoiceMethods">
                <button
                  class="btn btn-type-2 ms-2"
                  v-on:click="finish(1)"
                  :class="{ 'loading-spin': loadAddBtn && loadAddBtnId === 1 }"
                  :disabled="loadAddBtn"
                >
                  <span>{{ $t("pos.printInvoice") }}</span>
                </button>
                <button
                  class="btn btn-type-2 ms-2"
                  v-on:click="finish(2)"
                  :class="{ 'loading-spin': loadAddBtn && loadAddBtnId === 2 }"
                  :disabled="loadAddBtn"
                >
                  <span>{{ $t("pos.emailInvoice") }}</span>
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-type-4 ms-2"
                  v-on:click="openInvoice()"
                  :class="{ 'loading-spin': loadAddBtn }"
                  :disabled="loadAddBtn"
                >
                  <span>{{ $t("pos.invoice") }}</span>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <InvoiceModal
    ref="invoiceModal"
    v-model="lastInvoice"
    v-if="lastInvoice > 0"
  />
</template>

<script>
import BaseIcon from "@/components/icons/BaseIcon";
const bootstrap = require("bootstrap");
import { Vue3Lottie } from "vue3-lottie";
import scanJSON from "../../assets/animations/scan.json";
import processJSON from "../../assets/animations/process.json";
import successJSON from "../../assets/animations/success.json";
import errorJSON from "../../assets/animations/error.json";
import searchingJSON from "../../assets/animations/searching.json";
import barcodeRead from "@/modules/barcodeRead";
import http from "@/modules/http";
import Payment from "@/components/Pos/Payment";
import { useStore } from "vuex";
import CashControl from "../../modules/CashControl";
import stateTypes from "../../modules/StateTypes";
import { defineAsyncComponent } from "vue";
const InvoiceModal = defineAsyncComponent(() => import("./InvoiceModal.vue"));

export default {
  name: "POS",
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      iconJSON: scanJSON,
      loop: true,
      iconKey: 0,
      step: 1,
      payed: 0,
      selectedPayment: null,
      payments: [],
      showInvoiceMethods: false,
      addProducts: [{ id: null, quantity: 1, products: [] }],
      iconTimer: null,
      shop: undefined,
      loadAddBtn: false,
      loadAddBtnId: 0,
      retryPrintBtn: false,
      lastReceipt: null,
      lastSearchId: 0,
      lastInvoice: 0,
      packagingInfo: {
        status: this.$t("pos.scan"),
        text: null,
      },
      paymentID: "",
      coupon: "",
      couponError: null,
      couponAccepted: false,
      newOrder: {
        type: 0,
        tax_code: "",
        eu_tax_code: "",
        country_id: this.selectedWarehouse?.company?.country_id,
      },
      customers: [],
      states: [],
    };
  },
  computed: {
    stateTypes() {
      return stateTypes;
    },
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    countries() {
      return this.store.state.countries;
    },
    selectedCountry() {
      return this.countries.find((e) => e.id === this.newOrder.country_id);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    loggedUser() {
      return this.store.state.user;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    totalNeedToPay() {
      if (this.selectedPayment.type === 3) {
        return CashControl.rounded(
          this.total,
          this.selectedWarehouse?.default_currency
        );
      }
      return this.total;
    },
    remainingNeedToPay() {
      return this.total - this.totalPayed;
    },
    totalPayed() {
      let total = 0;
      for (const payment of this.payments) {
        total += payment.amount;
      }
      return total;
    },
    total() {
      let total = 0;
      for (const p of this.addProducts) {
        if (p.id > 0) {
          let product = p.products.find((e) => e.variant_id === p.id);
          if (
            product?.discount_price !== null &&
            product?.discount_price !== undefined
          ) {
            total += product?.discount_price * p.quantity;
          } else {
            total += product?.sell_price * p.quantity;
          }
        }
      }
      return total;
    },
  },
  methods: {
    getPrice(p) {
      let product = p.products.find((e) => e.variant_id === p.id);
      if (
        product?.discount_price !== null &&
        product?.discount_price !== undefined
      ) {
        return product?.discount_price * p.quantity;
      }
      return product?.sell_price * p.quantity;
    },
    setNullToZero(val) {
      if (val === null) {
        return 0;
      }
      return val;
    },
    checkCoupon() {
      if (this.coupon?.length < 2) {
        return;
      }

      let order = {
        items: [],
        shop_id: this.shop.id,
        payments: this.payments,
        shop: this.selectedWarehouse?.shop_id,
        coupon: this.coupon,
      };

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          order.items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }
      this.couponError = null;
      http
        .fetch("/orders/check-discount", order)
        .then((data) => {
          for (const item of data.orderItems) {
            let element = this.addProducts
              .find((e) => e.id === item.id)
              .products.find((e) => e.variant_id === item.id);
            element.sell_price = item.originalPrice;
            element.discount_price = item.price;
          }

          if (this.selectedPayment?.type !== 3) {
            this.payed = this.remainingNeedToPay;
          }
          this.couponAccepted = true;
        })
        .catch((data) => {
          this.couponError = data?.message;
        });
    },
    showModal() {
      this.coupon = "";
      this.paymentID = new Date().toISOString().slice(0, -5).replace("T", " ");
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
        this.$refs.modal.addEventListener("hidden.bs.modal", function () {
          barcodeRead.unload();
        });
      }
      if (this.shop === undefined) {
        http.fetch("/shops/" + this.selectedWarehouse?.shop_id).then((data) => {
          this.shop = data;
        });
      }
      this.myModal.show();
      barcodeRead.load(this.barcodeReaded);
    },
    barcodeReaded(code) {
      if (code === null) {
        return;
      }
      this.loop = true;
      this.iconJSON = searchingJSON;
      this.packagingInfo.status = this.$t("pos.searching");
      this.packagingInfo.text = this.$t("pos.wait");
      this.iconKey++;

      http
        .fetch("/products/products/search", {
          q: code,
          language: this.$i18n.locale,
          currency: this.selectedWarehouse?.default_currency,
          shop: this.selectedWarehouse?.shop_id,
        })
        .then((data) => {
          if (data.length > 0) {
            this.loop = false;
            this.iconJSON = successJSON;
            this.packagingInfo.status = this.$t("pos.added");
            this.packagingInfo.text = null;
            this.iconKey++;
            this.addProducts[0].products = data;
            this.addProducts[0].id = data[0].variant_id;
            this.changeProducts(data[0].variant_id);

            if (this.iconTimer !== null) {
              clearTimeout(this.iconTimer);
            }
            this.iconTimer = setTimeout(() => {
              this.loop = true;
              this.filtersTimeOut = null;
              this.iconJSON = scanJSON;
              this.packagingInfo.status = this.$t("pos.scan");
              this.packagingInfo.text = null;
              this.iconKey++;
            }, 3000);
          } else {
            this.loop = false;
            this.iconJSON = errorJSON;
            this.packagingInfo.status = this.$t("pos.not-found");
            this.packagingInfo.text = null;
            this.iconKey++;
          }
        })
        .catch((data) => {
          this.loop = false;
          this.iconJSON = errorJSON;
          this.packagingInfo.status = this.$t("pos.error");
          this.packagingInfo.text = data.message;
          this.iconKey++;
        });
    },
    changeProducts(variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      for (const p in this.addProducts) {
        if (this.addProducts[p].id === null) {
          if (p !== 0) {
            this.addProducts.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (this.addProducts[p].id === variant_id) {
          count++;
          quantity += this.addProducts[p].quantity;
          if (count > 1) {
            this.addProducts.splice(p, 1);
          }
        }
      }
      if (count > 1) {
        for (const p in this.addProducts) {
          if (this.addProducts[p].id === variant_id) {
            this.addProducts[p].quantity = quantity;
            break;
          }
        }
      }
      if (!hasNull) {
        this.addProducts.unshift({ id: null, quantity: 1, products: [] });
      }
      if (this.couponAccepted) {
        this.checkCoupon();
      }
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      http
        .fetch("/products/products/search", {
          q: search,
          language: this.$i18n.locale,
          currency: this.selectedWarehouse?.default_currency,
          shop: this.selectedWarehouse?.shop_id,
        })
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            p.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
    next(payment = null) {
      if (this.selectedPayment !== null) {
        if (this.payed > 0) {
          this.payments.push({
            method: this.selectedPayment.id,
            amount: this.payed,
          });
        }
        this.payed = 0;
      }
      if (payment !== null) {
        this.selectedPayment = payment;
      }
      if (this.selectedPayment.type !== 3) {
        this.payed = this.remainingNeedToPay;
      }
      this.step = 2;
    },
    deleteProduct(i) {
      this.addProducts.splice(i, 1);
    },
    fetchCustomers(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch("/customers/search", { email: search })
        .then((data) => {
          this.customers = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    changeCustomers(customer) {
      if (customer.phone !== undefined) {
        this.newOrder.type = customer.is_business ? 1 : 0;
        this.newOrder.phone = customer.phone;
        this.newOrder.first_name = customer.first_name;
        this.newOrder.last_name = customer.last_name;
        this.newOrder.invoice_house = customer.invoice_house;
        this.newOrder.invoice_city = customer.invoice_city;
        this.newOrder.invoice_post_code = customer.invoice_post_code;
        this.newOrder.invoice_street = customer.invoice_street;
      }
    },
    searchBusiness(tax, country) {
      http
        .fetch("/companies/search", { tax: tax, country: country })
        .then((data) => {
          if (data.length > 0) {
            this.newOrder.business_name = data[0].name;

            if (data[0].address !== undefined) {
              this.newOrder.country_id = this.countries.filter(
                (e) => e.code.toLowerCase() === data[0].country.toLowerCase()
              )[0]?.id;
              this.newOrder.invoice_house = data[0].address;
              this.newOrder.invoice_city = data[0].city;
              this.newOrder.invoice_post_code = data[0].postCode;
              this.newOrder.invoice_street = data[0].road;
            } else {
              this.newOrder.invoice_street = data[0].companyAddress;
            }
          }
        });
    },
    printReceipt(receipt, autoRetry = true) {
      if (this.shop.cassa_endpoint) {
        this.step = 3;
        this.iconJSON = processJSON;
        this.packagingInfo.status = this.$t("pos.wait");
        this.packagingInfo.text = "Connecting to the cassa";
        this.iconKey++;

        const _this = this;

        fetch(this.shop.cassa_endpoint, {
          method: "POST",
          body: receipt,
        })
          .then(() => {
            _this.success();
          })
          .catch(() => {
            location.href = "prodyposconnect://open";
            _this.lastReceipt = receipt;

            if (autoRetry) {
              setTimeout(function () {
                _this.printReceipt(_this.lastReceipt, false);
              }, 2000);
            }
            _this.loop = false;
            _this.iconJSON = errorJSON;
            _this.packagingInfo.status = this.$t("pos.error");
            _this.packagingInfo.text = "";
            _this.retryPrintBtn = true;
            _this.iconKey++;
          });
      } else {
        this.success();
      }
    },
    printReceiptAgain() {
      if (this.lastReceipt !== null) {
        this.printReceipt(this.lastReceipt);
      }
    },
    openInvoice() {
      this.showInvoiceMethods = true;
    },
    finish(receiptMethod = 0) {
      this.loadAddBtnId = receiptMethod;
      this.loadAddBtn = true;
      this.step = 3;
      this.iconJSON = processJSON;
      this.packagingInfo.status = this.$t("pos.wait");
      this.packagingInfo.text = "Waiting..";
      this.iconKey++;
      if (this.payed > 0) {
        this.payments.push({
          method: this.selectedPayment.id,
          amount: this.payed,
        });
      }

      let order = {
        items: [],
        shop_id: this.shop.id,
        payments: this.payments,
        price: this.totalNeedToPay,
        coupon: this.coupon,
        receiptMethod: receiptMethod,
      };

      if (receiptMethod > 0) {
        order = Object.assign(this.newOrder, order);
      }

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          order.items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }

      http
        .fetch("/orders/pos", order, true)
        .then((data) => {
          this.showInvoiceMethods = false;
          if (data.order) {
            this.$emit("newItem", data.order);
            if (data.receipt) {
              this.printReceipt(data.receipt);
            }
            if (data.invoice) {
              this.lastInvoice = data.invoice;
              const _this = this;
              setTimeout(() => {
                _this.$refs.invoiceModal.showModal();
              }, 1000);
            }
            this.success();
          } else {
            this.loadAddBtn = false;
          }
        })
        .catch(() => {
          this.step = 2;
          this.loadAddBtn = false;
        });
    },
    success() {
      this.selectedPayment = null;
      this.lastReceipt = null;
      this.retryPrintBtn = false;
      this.step = 1;
      this.payments = [];
      this.newOrder = {
        type: 0,
        tax_code: "",
        eu_tax_code: "",
        country_id: this.selectedWarehouse?.company?.country_id,
      };
      this.loop = false;
      this.iconJSON = successJSON;
      this.couponError = null;
      this.coupon = "";
      this.packagingInfo.status = this.$t("pos.successful");
      this.packagingInfo.text = this.$t("pos.next");
      this.iconKey++;
      if (this.iconTimer !== null) {
        clearTimeout(this.iconTimer);
      }
      this.iconTimer = setTimeout(() => {
        this.loop = true;
        this.filtersTimeOut = null;
        this.iconJSON = scanJSON;
        this.packagingInfo.status = this.$t("pos.scan");
        this.packagingInfo.text = null;
        this.iconKey++;
      }, 3000);

      this.loadAddBtn = false;
      this.payed = 0;
      this.paymentID = new Date().toISOString().slice(0, -5).replace("T", " ");
      this.addProducts = [{ id: null, quantity: 1, products: [] }];
      this.step = 1;
    },
    back() {
      this.selectedPayment = null;
      this.step = 1;
      this.payments = [];
    },
  },
  unmounted() {
    barcodeRead.unload();
  },
  components: {
    InvoiceModal,
    BaseIcon,
    Vue3Lottie,
    Payment,
  },
};
</script>
