<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-xl
        modal-fullscreen-lg-down
        modal-dialog-centered
        modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn btn-toolbar"
            :aria-label="$t(`cataloge.back`)"
            v-on:click="back()"
            v-if="step > 1"
          >
            <BaseIcon name="left-arrow2" />
          </button>
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t(`newSale.order`, { expr: editing ? "Edit" : "Add new" }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body" v-if="webshops.length > 0">
          <div
            v-if="step === 1"
            class="text-start custom-input whitebg-input m-2"
          >
            <div class="row">
              <div class="col-4 mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("filters.warehouse")
                }}</label>
                <v-select
                  :options="warehouses"
                  v-model="newOrder.warehouse_id"
                  label="name"
                  :placeholder="$t(`newSale.selectWarehouse`)"
                  :reduce="(c) => c.id"
                  disabled
                />
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newSale.autoWarehouse")
                  }}</span>
                  <input
                    type="checkbox"
                    v-model="newOrder.auto_warehouse"
                    value="1"
                  /><span class="checkmark mx-2"></span>
                </label>
              </div>
              <div class="col-4 mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("bankAccount.currency")
                }}</label>
                <v-select
                  :options="currencies"
                  v-model="newOrder.currency"
                  label="code"
                  :placeholder="$t(`newSale.selectCurrency`)"
                  :reduce="(c) => c.code"
                />
              </div>
              <div class="col-4 mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("newSale.orderPlace")
                }}</label>
                <v-select
                  :options="webshops"
                  v-model="newOrder.webshop_id"
                  label="name"
                  v-on:change="webshopChange"
                  :placeholder="$t(`newSale.selectWebshop`)"
                  :reduce="(c) => c.id"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 row">
                <div class="col-6 py-2 my-4">
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("newCustomer.person")
                    }}</span>
                    <input
                      type="radio"
                      :value="0"
                      v-model="newOrder.type"
                    /><span class="checkmark mx-2"></span>
                  </label>
                </div>
                <div class="col-6 py-2 my-4">
                  <label class="cstm-check dropdown-item">
                    <span class="text-black mx-2 regular-14">{{
                      $t("newCustomer.company2")
                    }}</span>
                    <input
                      type="radio"
                      :value="1"
                      v-model="newOrder.type"
                    /><span class="checkmark mx-2"></span>
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6 row">
                <div class="col-6 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("finance.email")
                  }}</label>
                  <v-select
                    v-model:options="customers"
                    v-on:search="(s, k) => fetchCustomers(s, k)"
                    v-model="newOrder.email"
                    :filterable="false"
                    placeholder=""
                    taggable
                    :create-option="(email) => ({ email: email })"
                    label="email"
                    :reduce="(c) => c.email"
                    v-on:option:selected="(e) => changeCustomers(e)"
                  />
                </div>
                <div class="col-6 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("addNewContactsModal.phone")
                  }}</label>
                  <input class="form-control" v-model="newOrder.phone" />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 col-md-6 row">
                <h3 class="bold-14">{{ $t("newSale.billingInfo") }}</h3>
                <div class="col-12 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.country")
                  }}</label>
                  <v-select
                    :options="countries"
                    v-model="newOrder.country_id"
                    v-on:change="searchStates(true)"
                    v-on:option:selected="searchStates(true)"
                    label="name"
                    :placeholder="$t(`address.selectCountry`)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="col-6 mb-3" v-if="newOrder.type === 0">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("finance.firstName") }}
                  </label>
                  <input class="form-control" v-model="newOrder.first_name" />
                </div>
                <div class="col-6 mb-3" v-if="newOrder.type === 0">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("finance.lastName") }}
                  </label>
                  <input class="form-control" v-model="newOrder.last_name" />
                </div>
                <div
                  class="col-6 mb-3"
                  v-if="
                    newOrder.type === 1 &&
                    selectedCountry?.is_eu &&
                    selectedCountry?.id !==
                      selectedWarehouse?.company?.country_id
                  "
                >
                  <label class="form-label bold-12 text-gray">
                    {{ $t("companies.euTaxNumber") }}
                  </label>
                  <input
                    class="form-control"
                    v-on:change="searchBusiness(newOrder.eu_tax_code)"
                    v-model="newOrder.eu_tax_code"
                  />
                </div>
                <div class="col-6 mb-3" v-else-if="newOrder.type === 1">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("companies.localTaxNumber") }}
                  </label>
                  <input
                    class="form-control"
                    v-on:change="searchBusiness(newOrder.tax_code)"
                    v-model="newOrder.tax_code"
                  />
                </div>
                <div class="col-6 mb-3" v-if="newOrder.type === 1">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("newSale.companyName") }}
                  </label>
                  <input
                    class="form-control"
                    v-model="newOrder.business_name"
                  />
                </div>
                <div
                  class="col-5 mb-3"
                  :class="selectedCountry?.need_area ? 'col-6' : 'col-12'"
                  v-if="selectedCountry?.need_state"
                >
                  <label class="form-label bold-12 text-gray">{{
                    stateTypes[setNullToZero(selectedCountry?.state_type)]?.name
                  }}</label>
                  <v-select
                    v-model:options="states"
                    v-model="newOrder.invoice_state"
                    placeholder=""
                    taggable
                    label="state_name"
                    :reduce="(c) => c.state_name"
                  />
                </div>
                <div
                  class="mb-3"
                  :class="selectedCountry?.need_state ? 'col-6' : 'col-12'"
                  v-if="selectedCountry?.need_area"
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("newSale.area")
                  }}</label>
                  <input class="form-control" v-model="newOrder.invoice_area" />
                </div>
                <div class="col-5 mb-3" v-if="selectedCountry?.need_post_code">
                  <label class="form-label bold-12 text-gray">{{
                    $t("companies.postCode")
                  }}</label>
                  <input
                    class="form-control"
                    v-on:change="searchPostCode(true)"
                    v-model="newOrder.invoice_post_code"
                  />
                </div>
                <div
                  class="mb-3"
                  :class="selectedCountry?.need_post_code ? 'col-7' : 'col-12'"
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.city")
                  }}</label>
                  <input
                    class="form-control"
                    v-on:change="copyInfo('city', newOrder.invoice_city)"
                    v-model="newOrder.invoice_city"
                  />
                </div>
                <div class="col-9 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.address")
                  }}</label>
                  <input
                    class="form-control"
                    v-on:change="copyInfo('street', newOrder.invoice_street)"
                    v-model="newOrder.invoice_street"
                  />
                </div>
                <div class="col-3 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("characteristicsModal.number")
                  }}</label>
                  <input
                    class="form-control"
                    v-on:change="copyInfo('house', newOrder.invoice_house)"
                    v-model="newOrder.invoice_house"
                  />
                </div>
              </div>
              <div
                class="col-12 col-md-6 row align-self-start"
                v-if="savedAddresses.length > 1"
              >
                <h3 class="bold-14">{{ $t("newSale.shippingInfo") }}</h3>
                <div class="mt-3">
                  <label
                    class="cstm-check dropdown-item"
                    v-for="address in savedAddresses"
                    :key="address.id"
                  >
                    <span class="text-black mx-2 regular-14">
                      {{ address.post_code }}
                      {{ address.city }},
                      {{ address.city }}
                      {{ address.street }}
                      {{ address.house }}
                    </span>
                    <input
                      type="radio"
                      v-on:change="addressSelected(address)"
                    /><span class="checkmark mx-2"></span>
                  </label>
                </div>
              </div>
              <div v-else class="col-12 col-md-6 row align-self-start">
                <h3 class="bold-14">{{ $t("newSale.shippingInfo") }}</h3>
                <div class="col-6 mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.country")
                  }}</label>
                  <v-select
                    :options="countries"
                    v-model="newOrder.delivery.country_id"
                    label="name"
                    :placeholder="$t(`address.selectCountry`)"
                    v-on:option:selected="searchStates(false)"
                    v-on:change="searchStates(false)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="col-6 mb-3">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("newSale.delivery2") }}
                  </label>
                  <v-select
                    :options="
                      webshops
                        .find((e) => e?.id === newOrder.webshop_id)
                        .deliveryMethods.filter(
                          (e) =>
                            e.supported_coutries.indexOf(
                              countries.find(
                                (f) => f?.id === newOrder.delivery.country_id
                              )?.code
                            ) > -1 &&
                            e.pivot.country_id ===
                              newOrder.delivery.country_id &&
                            e.DeliveryMethodPrices.findIndex(
                              (j) =>
                                j.country_id === newOrder.delivery.country_id &&
                                (j.warehouse_id === null ||
                                  j.warehouse_id === selectedWarehouse?.id)
                            ) > -1
                        )
                    "
                    v-model="newOrder.delivery.method"
                    label="name"
                    :placeholder="$t(`newSale.selectDelivery`)"
                    :reduce="(c) => c.id"
                  />
                </div>
                <div class="col-6 mb-3" v-if="newOrder.type !== 0">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("finance.firstName") }}
                  </label>
                  <input class="form-control" v-model="newOrder.first_name" />
                </div>
                <div
                  class="col-6 m-5"
                  v-else-if="
                    selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                  "
                ></div>
                <div class="col-6 mb-3" v-if="newOrder.type !== 0">
                  <label class="form-label bold-12 text-gray">
                    {{ $t("finance.firstName") }}
                  </label>
                  <input class="form-control" v-model="newOrder.last_name" />
                </div>
                <div class="col md-3" v-if="selectedDelivery?.type === 2">
                  <label class="form-label bold-12 text-gray">{{
                    $t("companies.postCode")
                  }}</label>
                  <input class="form-control" v-model="packagePointPostCode" />
                </div>
                <div
                  class="col-auto md-3 mt-4 pt-3"
                  v-if="selectedDelivery?.type === 2"
                >
                  <button
                    class="btn btn-primary"
                    v-on:click="searchPackagePoints"
                  >
                    Search
                  </button>
                </div>
                <div
                  class="col-12 md-3 mt-2"
                  v-if="selectedDelivery?.type === 2"
                >
                  <v-select
                    :options="packagePoints"
                    v-model="newOrder.delivery.package_point"
                    :placeholder="$t(`newSale.selectPackagePoint`)"
                    :filter="filterPackagePoint"
                    :reduce="(c) => c.pointId"
                  >
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col">
                          <b>{{ option.name }}</b>
                        </div>
                        <div class="col-auto">
                          {{ option.openingTime }}
                        </div>
                        <div class="col-12">
                          {{ option.postCode }} {{ option.city }}
                          {{ option.street }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <div class="row text-wrap">
                        <div class="col">
                          <b>{{ option.name }}</b>
                        </div>
                        <div class="col-auto">
                          {{ option.openingTime }}
                        </div>
                        <div class="col-12">
                          {{ option.postCode }} {{ option.city }}
                          {{ option.street }}
                        </div>
                      </div>
                    </template>
                  </v-select>
                </div>

                <div
                  class="col-5 mb-3"
                  :class="
                    selectedDeliveryCountry?.need_area ? 'col-6' : 'col-12'
                  "
                  v-if="
                    selectedDeliveryCountry?.need_state &&
                    (selectedDelivery?.type === 1 ||
                      selectedDelivery?.type === 5)
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    stateTypes[
                      setNullToZero(selectedDeliveryCountry?.state_type)
                    ]?.name
                  }}</label>
                  <v-select
                    v-model:options="statesDelivery"
                    v-model="newOrder.delivery.state"
                    placeholder=""
                    taggable
                    label="state_name"
                    :reduce="(c) => c.state_name"
                  />
                </div>
                <div
                  class="mb-3"
                  :class="
                    selectedDeliveryCountry?.need_state ? 'col-6' : 'col-12'
                  "
                  v-if="
                    selectedDeliveryCountry?.need_area &&
                    (selectedDelivery?.type === 1 ||
                      selectedDelivery?.type === 5)
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("newSale.area")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newOrder.delivery.area"
                  />
                </div>
                <div
                  class="col-5 mb-3"
                  v-if="
                    selectedDeliveryCountry?.need_post_code &&
                    (selectedDelivery?.type === 1 ||
                      selectedDelivery?.type === 5)
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("companies.postCode")
                  }}</label>
                  <input
                    class="form-control"
                    v-on:change="searchPostCode(false)"
                    v-model="newOrder.delivery.post_code"
                  />
                </div>
                <div
                  class="mb-3"
                  :class="
                    selectedDeliveryCountry?.need_post_code ? 'col-7' : 'col-12'
                  "
                  v-if="
                    selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.city")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newOrder.delivery.city"
                  />
                </div>
                <div
                  class="col-9 mb-3"
                  v-if="
                    selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("address.address")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newOrder.delivery.street"
                  />
                </div>
                <div
                  class="col-3 mb-3"
                  v-if="
                    selectedDelivery?.type === 1 || selectedDelivery?.type === 5
                  "
                >
                  <label class="form-label bold-12 text-gray">{{
                    $t("characteristicsModal.number")
                  }}</label>
                  <input
                    class="form-control"
                    v-model="newOrder.delivery.house"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="step === 2"
            class="text-start custom-input whitebg-input m-2"
          >
            <div class="row mx-md-5 px-md-5 mt-4">
              <div class="col-12">
                <h3 class="bold-14">{{ $t("newSale.products") }}</h3>
              </div>
            </div>
            <div
              class="row custom-input mx-md-5 px-md-5 whitebg-input"
              v-for="p in addProducts"
              :key="p.id"
            >
              <div class="col-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("ingredients.quantity")
                }}</label>
                <input
                  type="number"
                  v-model="p.quantity"
                  class="form-control"
                  min="1"
                />
              </div>
              <div class="col-7">
                <div class="mb-3">
                  <label class="form-label bold-12 text-gray">{{
                    $t("chart1.product")
                  }}</label>
                  <v-select
                    v-model:options="p.products"
                    v-on:search="(s, k) => fetchProducts(s, k, p)"
                    v-model="p.id"
                    :filterable="false"
                    append-to-body
                    :placeholder="$t(`copyProducts.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    v-on:option:selected="(e) => changeProducts(e.variant_id)"
                    v-on:option:deselected="() => changeProducts(0)"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        {{ $t("copyProducts.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>{{
                        $t("copyProducts.startTypingToSearchForAProduct")
                      }}</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="mediaServer + '/' + option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("filters.price")
                }}</label>
                <div
                  v-if="p.id > 0 && getPrice(p) !== getPrice(p, true)"
                  class="strike-trough"
                >
                  {{ getPrice(p, true) }}
                  {{ newOrder?.currency }}
                </div>
                <div v-if="p.id > 0">
                  {{ getPrice(p) }}
                  {{ newOrder?.currency }}
                </div>
              </div>
            </div>
            <div class="row mx-md-5 px-md-5 mt-4">
              <div class="col-12 failed badge w-100" v-if="couponError">
                <span>{{ couponError }}</span>
              </div>
              <div class="col-5 mb-2">
                <label class="form-label bold-12 text-gray">
                  {{ $t("newSale.payment") }}
                </label>
                <v-select
                  :options="
                    webshops
                      .find((e) => e.id === newOrder.webshop_id)
                      .paymentMethods.filter(
                        (e) =>
                          (e.supported_currencies === null ||
                            e.supported_currencies.indexOf(newOrder.currency) >
                              -1) &&
                          (e.delivery_method_id === null ||
                            e.delivery_method_id ===
                              newOrder.delivery.method) &&
                          (e.only_same_country !== true ||
                            newOrder.delivery.country_id ===
                              selectedWarehouse.country_id) &&
                          (e.only_official_currency !== true ||
                            newOrder.currency ===
                              selectedDeliveryCountry.default_currency)
                      )
                  "
                  v-model="newOrder.payment_method"
                  label="name"
                  :placeholder="$t(`newSale.selectPayment`)"
                  :reduce="(c) => c.id"
                />
              </div>
              <div class="col-4 mb-2">
                <label class="form-label bold-12 text-gray">
                  {{ $t("newSale.coupon") }}
                </label>
                <input
                  type="text"
                  v-model="newOrder.coupon"
                  v-on:change="checkCoupon"
                  class="form-control"
                />
              </div>
              <div class="col-3 mb-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("newSale.discount")
                }}</label>
                <div class="fix-end-input">
                  <input
                    type="number"
                    class="form-control"
                    v-model="discount"
                    min="0"
                    :max="discountType == 1 ? 100 : 99999999999"
                  />
                  <select v-model="discountType">
                    <option :value="1">%</option>
                    <option :value="2">
                      {{ newOrder.currency }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6 mb-5">
                <label class="form-label bold-12 text-gray">
                  {{ $t("newSale.commentToTheShop") }}
                </label>
                <textarea
                  v-model="newOrder.shop_comment"
                  class="form-control"
                ></textarea>
              </div>
              <div class="col-6 mb-5">
                <label class="form-label bold-12 text-gray">
                  {{ $t("newSale.commentToTheDelivery") }}
                </label>
                <textarea
                  v-model="newOrder.delivery_comment"
                  class="form-control"
                ></textarea>
              </div>
              <div class="col-12 mx-md-5 px-md-5">
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newSale.freeShipping")
                  }}</span>
                  <input
                    type="checkbox"
                    v-model="newOrder.free_shipping"
                    value="1"
                  /><span class="checkmark mx-2"></span>
                </label>
                <label class="cstm-check dropdown-item">
                  <span class="text-black mx-2 regular-14">{{
                    $t("newSale.preorder")
                  }}</span>
                  <input
                    type="checkbox"
                    v-model="newOrder.preorder"
                    value="1"
                  /><span class="checkmark mx-2"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-if="editing"
            v-on:click="saveEdit"
          >
            {{ $t("finance.save") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-else-if="step < 2"
            v-on:click="next"
          >
            {{ $t("addNewIntegration.next") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-else
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
            v-on:click="addOrder"
          >
            {{ $t("newSale.addNewOrder") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import barcodeRead from "../../modules/barcodeRead";
import BaseIcon from "../icons/BaseIcon";
import store from "@/store";
import StateTypes from "../../modules/StateTypes";
const bootstrap = require("bootstrap");

export default {
  name: "NewSale",
  props: {
    modelValue: Object,
    editing: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "newItem", "save"],
  data() {
    return {
      store: useStore(),
      myModal: null,
      success: false,
      loadAddBtn: false,
      step: 1,
      countries: [],
      webshops: [],
      customers: [],
      states: [],
      statesDelivery: [],
      addProducts: [{ id: null, quantity: 1, products: [] }],
      savedAddresses: [],
      stateTypes: StateTypes,
      packagePointPostCode: "",
      packagePoints: [],
      discountType: 1,
      discount: 0,
      couponError: null,
      lastSearchId: 0,
      newOrder: {
        type: 0,
        tax_code: "",
        eu_tax_code: "",
        delivery: {
          method: null,
          country_id: null,
        },
      },
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.newOrder.warehouse_id);
    },
    selectedCountry() {
      return this.countries.find((e) => e.id === this.newOrder.country_id);
    },
    selectedDeliveryCountry() {
      return this.countries.find(
        (e) => e.id === this.newOrder.delivery.country_id
      );
    },
    selectedDelivery() {
      return this.webshops
        .find((e) => e.id === this.newOrder.webshop_id)
        ?.deliveryMethods?.find(
          (e) => e.id === this.newOrder?.delivery?.method
        );
    },
    deliveryMethodType() {
      return this.selectedDelivery?.type;
    },
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });

    this.$refs.modal.addEventListener("hidden.bs.modal", function () {
      barcodeRead.unload();
    });
  },
  unmounted() {
    barcodeRead.unload();
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        let data = this.modelValue;
        if (data.delivery === null || data.delivery === undefined) {
          data.delivery = {};
        }
        this.newOrder = data;
      },
    },
    warehouse() {
      this.newOrder.warehouse_id = this.warehouse;
    },
  },
  methods: {
    searchPackagePoints() {
      this.packagePoints = [];
      http
        .fetch(
          "/deliveries/package-points/" +
            this.selectedDelivery.id +
            "/" +
            this.selectedDeliveryCountry.code +
            "/" +
            this.packagePointPostCode
        )
        .then((data) => {
          this.packagePoints = data.list;
        });
    },
    showModal() {
      this.newOrder.warehouse_id = this.warehouse;
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.showAddModal();
      barcodeRead.load(this.barcodeReaded);
    },
    saveEdit() {
      this.$emit("update:modelValue", this.newOrder);
      this.$emit("save");
      this.myModal.hide();
    },
    addOrder() {
      this.loadAddBtn = true;
      let order = Object.assign({}, this.newOrder);
      order.items = [];
      if (order.type === 1) {
        delete order.first_name;
        delete order.last_name;
      } else {
        delete order.tax_code;
        delete order.eu_tax_code;
        delete order.business_name;
      }

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          order.items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }

      if (this.discountType === 1) {
        order.discount = this.discount;
      } else if (this.discountType === 2) {
        order.coupon_amount = this.discount;
      }

      http
        .fetch("/orders", order, true)
        .then((data) => {
          this.$emit("newItem", data);
          this.myModal.hide();

          this.$emit("update:show", false);
          this.loadAddBtn = false;
          this.addProducts = [{ id: null, quantity: 1, products: [] }];
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    copyInfo(key, val) {
      if (
        this.newOrder.delivery[key] === undefined ||
        this.newOrder.delivery[key]?.length === 0
      ) {
        this.newOrder.delivery[key] = val;
      }
    },
    setNullToZero(val) {
      if (val === null) {
        return 0;
      }
      return val;
    },
    getPrice(p, original = false) {
      let product = p.products.find((e) => e.variant_id === p.id);
      if (
        !original &&
        product?.discount_price !== null &&
        product?.discount_price !== undefined
      ) {
        return product?.discount_price * p.quantity;
      }
      return product?.sell_price * p.quantity;
    },
    checkCoupon() {
      if (this.newOrder?.coupon?.length < 2) {
        return;
      }

      let order = Object.assign({}, this.newOrder);
      order.items = [];
      if (order.type === 1) {
        delete order.first_name;
        delete order.last_name;
      } else {
        delete order.tax_code;
        delete order.eu_tax_code;
        delete order.business_name;
      }

      for (const product of this.addProducts) {
        if (product.id !== null && product.id !== undefined) {
          order.items.unshift({
            id: product.id,
            quantity: product.quantity,
          });
        }
      }
      this.couponError = null;
      http
        .fetch("/orders/check-discount", order)
        .then((data) => {
          for (const item of data.orderItems) {
            let element = this.addProducts
              .find((e) => e.id === item.id)
              .products.find((e) => e.variant_id === item.id);
            element.sell_price = item.originalPrice;
            element.discount_price = item.price;
          }
        })
        .catch((data) => {
          this.couponError = data?.message;
        });
    },
    searchStates(isInvoice) {
      let country;
      if (isInvoice) {
        this.copyInfo("country_id", this.newOrder.country_id);
        this.states = [];

        if (!this.selectedCountry?.need_state) {
          return;
        }

        country = this.countries.find(
          (e) => e.id === this.newOrder.country_id
        ).code;
      } else {
        this.statesDelivery = [];
        if (!this.selectedDeliveryCountry?.need_state) {
          return;
        }

        country = this.countries.find(
          (e) => e.id === this.newOrder.delivery.country_id
        ).code;
      }
      http.fetch("/search-states", { country: country }).then((data) => {
        if (isInvoice) {
          this.states = data;
        } else {
          this.statesDelivery = data;
        }
      });
    },
    searchPostCode(isInvoice) {
      let data = {};
      if (isInvoice) {
        this.copyInfo("post_code", this.newOrder.invoice_post_code);

        data = {
          country: this.countries.find((e) => e.id === this.newOrder.country_id)
            .code,
          post_code: this.newOrder.invoice_post_code,
        };
      } else {
        data = {
          country: this.countries.find(
            (e) => e.id === this.newOrder.delivery.country_id
          ).code,
          post_code: this.newOrder.delivery.post_code,
        };
      }
      http.fetch("/search-post-code", data).then((data) => {
        if (Object.keys(data).length > 0) {
          if (isInvoice) {
            this.newOrder.invoice_city = data.name;
            this.copyInfo("city", this.newOrder.invoice_city);
          } else {
            this.newOrder.delivery.city = data.name;
          }
        }
      });
    },
    searchBusiness(tax) {
      http.fetch("/companies/search", { tax: tax }).then((data) => {
        if (data.length > 0) {
          this.newOrder.business_name = data[0].name;

          if (data[0].address !== undefined) {
            this.newOrder.country_id = this.countries.filter(
              (e) => e.code === data[0].country
            )[0].id;
            this.newOrder.invoice_house = data[0].address;
            this.newOrder.invoice_city = data[0].city;
            this.newOrder.invoice_post_code = data[0].postCode;
            this.newOrder.invoice_street = data[0].road;
          } else {
            this.newOrder.invoice_street = data[0].companyAddress;
          }
        }
      });
    },
    barcodeReaded(code) {
      let send = {
        q: code,
        language: this.$i18n.locale,
        currency: this.newOrder.currency,
      };

      if (this.modelValue?.warehouse_id > 0) {
        send.warehouse_id = this.modelValue.warehouse_id;
      }
      http.fetch("/products/products/search", send).then((data) => {
        if (data.length > 0) {
          this.addProducts[0].products = data;
          this.addProducts[0].id = data[0].variant_id;
          this.changeProducts(data[0].variant_id);
        }
      });
    },
    changeProducts(variant_id) {
      let hasNull = false;
      let quantity = 0;
      let count = 0;
      for (const p in this.addProducts) {
        if (this.addProducts[p].id === null) {
          if (p !== 0) {
            this.addProducts.splice(p, 1);
          } else {
            hasNull = true;
          }
        } else if (this.addProducts[p].id === variant_id) {
          count++;
          quantity += this.addProducts[p].quantity;
          if (count > 1) {
            this.addProducts.splice(p, 1);
          }
        }
      }
      if (count > 1) {
        for (const p in this.addProducts) {
          if (this.addProducts[p].id === variant_id) {
            this.addProducts[p].quantity = quantity;
            break;
          }
        }
      }
      if (!hasNull) {
        this.addProducts.unshift({ id: null, quantity: 1, products: [] });
      }
    },
    showAddModal() {
      if (this.countries.length === 0) {
        http.fetch("/countries").then((data) => {
          this.countries = data;
        });
      }
      if (this.webshops.length === 0) {
        http.fetch("/webshops").then((data) => {
          this.webshops = data;
          if (
            this.newOrder.webshop_id === undefined ||
            this.newOrder.webshop_id === null ||
            this.newOrder.webshop_id < 0
          ) {
            this.newOrder.webshop_id = data[0].id;
            this.webshopChange();
          }
        });
      }
      if (
        this?.newOrder &&
        this?.newOrder?.delivery &&
        this?.newOrder?.delivery?.post_code
      ) {
        this.packagePointPostCode = this.newOrder.delivery.post_code;
      }
    },
    webshopChange() {
      this.newOrder.currency = this.webshops.find(
        (e) => e.id === this.newOrder.webshop_id
      ).currency_code;
    },
    back() {
      if (this.step > 0) {
        this.step--;
      }
    },
    next() {
      if (this.newOrder.delivery.country_id === null) {
        store.commit("setApiError", { message: "Delivery country missing" });
        return;
      }
      this.step++;
    },
    changeCustomers(customer) {
      if (customer.phone !== undefined) {
        this.newOrder.phone = customer.phone;
        this.newOrder.first_name = customer.first_name;
        this.newOrder.last_name = customer.last_name;
        this.newOrder.country_id = customer.country_id;
        this.newOrder.invoice_house = customer.invoice_house;
        this.newOrder.invoice_city = customer.invoice_city;
        this.newOrder.invoice_post_code = customer.invoice_post_code;
        this.newOrder.invoice_street = customer.invoice_street;
        this.newOrder.invoice_state = customer.invoice_state;
        this.newOrder.invoice_area = customer.invoice_area;

        this.savedAddresses = [];
        if (customer.crmItemAddresses.length === 1) {
          this.newOrder.delivery.country_id =
            customer.crmItemAddresses[0].country_id;
          this.newOrder.delivery.house = customer.crmItemAddresses[0].house;
          this.newOrder.delivery.city = customer.crmItemAddresses[0].city;
          this.newOrder.delivery.post_code =
            customer.crmItemAddresses[0].post_code;
          this.newOrder.delivery.street = customer.crmItemAddresses[0].street;
        } else {
          this.savedAddresses = customer.crmItemAddresses;
        }
      }
    },
    addressSelected(address) {
      this.newOrder.delivery.country_id = address.country_id;
      this.newOrder.delivery.house = address.house;
      this.newOrder.delivery.city = address.city;
      this.newOrder.delivery.post_code = address.post_code;
      this.newOrder.delivery.street = address.street;
      this.newOrder.delivery.state = address.state;
      this.newOrder.delivery.area = address.area;
      this.savedAddresses = [];
    },
    fetchCustomers(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch("/customers/search", { email: search })
        .then((data) => {
          this.customers = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    filterPackagePoint(options, search) {
      return search.length
        ? options.filter(
            (e) =>
              e.id?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.name?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.city?.toLowerCase()?.indexOf(search.toLowerCase()) > -1 ||
              e.postCode?.indexOf(search) === 0
          )
        : options;
    },
    fetchProducts(search, loading, p) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      let send = {
        q: search,
        language: this.$i18n.locale,
        currency: this.newOrder.currency,
      };

      if (this.modelValue?.warehouse_id > 0) {
        send.warehouse_id = this.modelValue.warehouse_id;
      }
      http
        .fetch("/products/products/search", send)
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            p.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  components: { BaseIcon },
};
</script>
